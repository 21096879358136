import React from "react";
import LogoAuta from "../../../images/logo-auta.png";
import "../style/style.css";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

export const HomeComingSoonComponent = () => {
  let isPageWide = useMediaQuery("(min-width: 700px)");

  return (
    <div className="container-coming-soon d-flex align-items-center justify-content-center flex-column">
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ height: "100%", marginTop: isPageWide ? "100px" : "50px" }}
      >
        <img
          src={LogoAuta}
          alt="logo-auta"
          className="logo-auta-coming mb-5"
          style={{
            width: isPageWide ? "400px" : "235px",
            height: isPageWide ? "200px" : "115px",
          }}
        />
        <p
          className="text-description-coming-soon mt-2"
          style={{
            fontSize: isPageWide ? "30px" : "18px",
          }}
        >
          Estamos construyendo algo emocionante, <br /> una experiencia única de
          compra y venta de autos.
        </p>
        <p
          className="text-footer-coming-soon mb-4"
          style={{ fontSize: isPageWide ? "30px" : "18px" }}
        >
          ¡Estate atento a nuestro lanzamiento!
        </p>
        <p
          className="text-description-coming-soon mt-2"
          style={{
            fontSize: isPageWide ? "22px" : "16px",
          }}
        >
          Mientras tanto... <br />
          Visitá nuestro cotizador y descubrí la oferta inicial que tenemos para
          tu auto.
        </p>
      </div>
      <a
        href="https://cotizador.auta.com.ar"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: "inherit",
          // marginTop: isPageWide ? "" : "50px",
        }}
      >
        <button className="btn-redirect-cotizador mt-5">
          ¡Vende tu auto rápido, fácil y seguro!
        </button>
      </a>
      <div
        style={{ height: "100%" }}
        className="d-flex align-items-end justify-content-center mb-4"
      >
        <p className="text-footer-coming-soon">
          2024 Kardur S.A. - Manuel García 250, CABA (1248) - Tel: 011
          15-5263-8870
        </p>
      </div>
    </div>
  );
};
